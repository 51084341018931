import type { AdditionalProduct, Recipe } from '@ruokaboksi/api-client';

export const fallbackRecipe: Recipe = {
  id: 'not-available',
  title: 'selected_products.fallback_recipe',
  seoTitle: '',
  description: null,
  seoDescription: null,
  bottomDescription: null,
  ingredientsList: [],
  isPremium: false,
  premiumPrice: 0,
  sku: null,
  mainImage: null,
  plateImage: null,
  allergens: [],
  size: 'large',
  diets: [],
  recipeLabels: [],
  serves: 4,
  preparationTime: 0,
};

export const fallbackAdditionalProduct: AdditionalProduct = {
  id: 'not-available',
  price: 0,
  title: 'selected_products.fallback_additional_product',
  description: null,
  mainImage: null,
  taxRate: 0,
  sku: 'not-available',
  orderingNumber: null,
  plateImage: null,
  packagingType: null,
  maxPerDelivery: 0,
};
